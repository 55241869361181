$(function(){
  $(".fileuplaodcv").AjaxFileUpload({
    onChange: function(filename){
      $(".btn-file").addClass("uploading");
    },
    onComplete: function(filename, response) {
        $(".fileuplaodcvupdate").val(response)
        $(".txt_up").text(filename);
        $(".btn-file").removeClass("uploading");
      }
  });

  $(".form-radio input[type='radio']").change(function(){

    var btn = $(this).closest('.custom-form').find('.self-form-btn');
    btn.attr('disabled', false);

    $(this).closest('.custom-tr').find('.form-radio-title').css('color', '')

  });

  $('.self-form-btn').on('click', function(e){
    var all_answered = true;
    
    $("input:radio").each(function(index, element){ 
      var name = $(this).attr("name");
      if($('input:radio[name="'+name+'"]:checked').length == 0){
        $(this).closest('.custom-tr').find('.form-radio-title').css('color', 'red')
        all_answered = false;
      }
    });

    if (!all_answered) {
      e.preventDefault();
    }
    
  });

  
});